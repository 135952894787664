@use 'variables' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

img {
    display:unset;
}

@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  src: url(/assets/fonts/Urbanist-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0100-017F, U+0180-024F, U+1E00-1EFF, U+20A0-20CF;
  font-display: swap;
}

@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/Urbanist-Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0100-017F, U+0180-024F, U+1E00-1EFF, U+20A0-20CF;
  font-display: swap;
}

@font-face {
  font-family: fallback-font;
  ascent-override: 90%;
  descent-override: 26%;
  line-gap-override: normal;
  advance-override: 10;
  src: local(Roboto);
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Urbanist', sans-serif, fallback-font;
}


// Specifies large size for all components to match the previous defaults
// This may not be needed for your project. Please consult https://www.infragistics.com/products/ignite-ui-angular/angular/components/general/update-guide for more details.
:root {
  --ig-size: var(--ig-size-large);
}

/* autoprefixer grid: on */
html,
body {
  overscroll-behavior: none; /* Pour désactiver le rebond sur le body */
  height: 100%;
  overflow: hidden;
  margin: 0;
}

@include core();
@include theme( $palette: $palette, $schema: $schema );


.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.resetDefaultCSSModal .mdc-dialog__surface {
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.backdropModal {
  background: rgba(0, 0, 0, 0.5);
}

button:not(.notHover):hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

button.notHover:hover {
  cursor:auto;
}

.btnBase {
  background-color: #FF6602;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #FFFFFF;
  height: 40px;
  justify-content: center;
  border: 0px;
  display: flex;
  align-items: center;
  min-width: 80px;
  padding:8px;
}

.btnBase img {
    margin-right:8px;
}

.btnBase.btnCancel {
  background-color: #DC1E27;
}

.btnBase.btnWhite {
  background-color: #FFFFFF;
  color: #525252;
  border: 1px #DCDCDC solid;
}

.btnBase.btnSave {
  background-color: #1DA54D;
}

.btnBase.btnWhite.btnSmall,
.btnBase.btnCancel.btnSmall,
.btnBase.btnSmall {
  font-size: 12px;
  height: 30px;
  padding: 6px;
}

.btnBase.btnCancel img {
  margin-right: 10px;
  vertical-align: text-top;
}

.btnBase.btnSave img {
  margin-right: 10px;
  vertical-align: text-top;
}

.grid_filter {
  width: 526px;
  height: 40px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #E9E9E9;
}

.wrapperListBox {
  border: 1px solid #E9E9E9;
  border-radius: 8px;
}

.wrapperListBox.error {
  border-color: red;
}

.inputLabel.error,
input.error {
  border-color: red;
}

textarea:focus {
  outline: 0px solid #E9E9E9;
}

textarea.error {
  border-color: red;
}

app-list-button-push.error {
  border-color: red;
}

.txtInput {
  border: none;
  outline: none;
  background: #FFFFFF;
  width: 100px;
  height: 100%;
  padding: 0px 12px 0px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.txtInput.w-full{
    width:100%
}

.txtInput:-webkit-autofill,
.txtInput:-webkit-autofill:hover,
.txtInput:-webkit-autofill:focus,
.txtInput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.inputLabel {
  width: 100%;
  position: relative;
  display: flex;
  border: 1px solid #dee2eb;
  border-radius: 8px;
  align-items: center;
  height:40px;
}

.inputLabel .suffixes {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #525252;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  height: 40px;
  display: flex;
  align-items: center;
}

input::placeholder {
  color: #BDBDBD;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.listBoxInput {
  background: #E9E9E9;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: right;
  padding: 1px;
  width: 200px;
  border-radius: 0px 8px 8px 0px;
}

.listBoxInput.bigList{
  width: 100%;
}

.labelForm {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  color: #888888;
}

.inputRequired:after {
  content: " *";
  color: #DC1E27;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.txtErrorInputLabel {
  display: block;
  width: 100%;
  margin-top: 2px;
  font-size: 12px;
  color: #cc0000;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  border: 1px solid #E9E9E9;
  border-radius: 8px;
  resize: none;
  padding: 8px;
  vertical-align: bottom;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px 4px 4px 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 2px solid #DCDCDC;
}

input[type='checkbox']:checked {
  background-color: #000;
}

input[type='checkbox']:checked:after {
  content: '\2713';
  color: white;
  position: absolute;
  line-height: 1rem;
  font-size: 1rem;
  padding-left: 0.15rem;
}

input[type='radio'] {
  transform: scale(1.5);
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font: inherit; /* Assure que la police est la même que celle de l'environnement parent */
  padding: 0;
}

.link-button:hover {
  color: darkblue;
}

.link-button:focus {
  outline: none;
  text-decoration: none;
}
.name {
  color: #888888;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.name.bold {
  color: #000000;
}

.titleModal {
  color: #181A1C;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.headerModal {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #EEEEEE;
  flex-shrink: 0;
}

.headerModal .btnIconModal {
  border: none;
  outline: none;
  background-color: white;
  position: absolute;
  top: 0;
  width: 24px;
  right: 24px;
  cursor: pointer;
}

.headerModal .btnIconModal img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.paddingHorizontalModal {
  padding-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.upperCase {
  text-transform: uppercase;
}

.colorOrange {
  color: #FF6602;
}

.maxWidth200{
  max-width: 200px
}

.maxWidth250 {
  max-width: 250px
}

.listButtonPush {
  display: flex;
  flex-flow: row;
}

.wrapPageAuth {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageAuth {
  max-width: 600px;
  padding-top: 15px;
  padding-bottom: 25px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 600px;
  max-height: 80dvh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }
}

.textFooter {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  color: #757A81;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  flex-flow: row-reverse;
}

.wrapperInputButtonPush {
  display: flex;
  align-items: center;
  height:40px;
}

.inputButtonPush {
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 8px;
  align-items: center;
  height: 40px;
}

.wrapperInputButtonPush.inputButtonPush,
.wrapperInputButtonPush.inputLabel
{
  //width: fit-content;
}

app-list-button-push {
    height:100%;
    width:100%
}

.wrapperFit {
//  width: fit-content;
}


.titleIntervention {
  padding-bottom: 10px;
}

.middleTitle {
  padding-top: 20px;
}

.titleIntervention span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #FF6602;
  flex-grow: 1;
  overflow: hidden;
  //white-space: nowrap;
  display: flex;
  width: 100%;
}

.wrapperSingleLine {
  padding-bottom: 5px;
}

.statusWrapper,
.statusServiceWrapper,
.residentWrapper,
.twoColumnWrapper {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.plannedOnWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
}

.oneColumnWrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start
}

.threeColumnWrapper {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.threeColumnWrapper.noGap {
  grid-column-gap: 0px;
}
.threeColumnWrapper.noWrap {
  flex-wrap: nowrap;
}

  .fourColumnWrapper {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .statusWrapper {
    padding-bottom: 5px;
  }

  .titleIntervention span::after {
    content: '';
    flex: 1;
    border-bottom: 1px dashed currentColor;
    margin-left: 5px;
    margin-bottom: 8px;
  }

  .statusServiceWrapper .serviceText {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #525252;
  }

  .checkPreviousQuarter {
    margin-right: 10px;
    display: flex;
    place-content: flex-end;
  }

  .generalDirective {
    margin-right: 10px;
    display: flex;
    background-color: #FAFAFA;
  }

  .generalDirective div {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 8px;
    border-radius: 8px;
  }

  .checkPreviousQuarter span {
    background: #FEFFC1;
    border-radius: 8px;
    padding: 0px 8px 0px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .checkPreviousQuarter span {
    height: 50%
  }

.wrapperPreviousNext {
  display: flex;
  width: 100%;
  background-color: #F3F3F3;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #E9E9E9;
}

  .wrapperPreviousNext .buttonReturn {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .wrapperPreviousNext .buttonPreviousNext {
    display: flex;
    justify-content: end;
    gap: 30px;
    padding: 15px;
  }

  .interventionWrapper {
    width: 100%;
    display: flex;
    gap: 10px;
    height: 100%;
  }

  .main {
    overflow-y: auto;
    padding: 2px;
    height: calc(100dvh - 75px);
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .interventionWrapper .leftColumn {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
  }

  .interventionWrapper .leftColumn .leftColumnSpace {
    min-height: calc(100vh - 280px);
    height: 100%;
  }

  .interventionWrapper .leftColumn .actionButton {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
  }

  .maxwidth-fitcontent {
    max-width: fit-content;
  }

